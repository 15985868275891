.month-container {
  position: relative;
  margin: 0 !important;
  .month-name {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 10px;
  }
}
.chip-days {
  position: relative;
  z-index: 20;
  margin-left: -50px !important;
  span {
    z-index: 20;
  }
}
.chip-dates {
  position: relative;
  z-index: 20;
  span {
    z-index: 20;
    padding-right: 50px;
    padding-left: 15px;
  }
}
.bookings-list {
  .chip-dates {
    span {
      min-width: 350px;
      max-width: 350px;
    }
  }
  .chip-days {
    span {
      min-width: 90px;
      max-width: 90px;
    }
  }
}
.mobile-view {
  .bookings-list {
    .chip-dates {
      span {
        min-width: 270px;
        max-width: 270px;
      }
    }
  }
}
/*
.month-container:not(:first-child) {
}
*/
.MuiPickersCalendarHeader-labelContainer {
  display: none !important;
}
.MuiPickersArrowSwitcher-root {
  display: none !important;
}
