button {
    text-transform: none !important;
}
.css-sqfbie-MuiTypography-root-MuiDayCalendar-weekNumber {
    min-width: 36px;
}
.MuiPaper-root {
    color: #333 !important;
}


* {
    box-sizing: border-box;
}

body {
    margin: 0;
}

body, .App {
    overflow-x: hidden;
}
