.customer-bookings {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
        padding: 8px;
        border-bottom: 1px solid #ddd;
    }

    th {
        background-color: #f2f2f2;
        font-weight: bold;
        text-align: right;
    }
    td {
        text-align: right;
    }
    td.left, th.left {
        text-align: left;
    }

    tbody {
        tr:nth-child(even) {
            background-color: #f2f2f2;
        }

        tr:hover {
            background-color: #ddd;
        }
    }
}
